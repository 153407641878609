<template>
  <div class="trials-content">
    <ClinicalTrial
      v-for="(item, index) in clinicaltrials.trials"
      :key="index"
      v-bind="item"
    ></ClinicalTrial>
  </div>
</template>

<script>
import ClinicalTrial from "@/components/ClinicalTrialMobile.vue";

export default {
  name: "Trials",
  components: { ClinicalTrial },
  data() {
    return {};
  },
  computed: {},
  props: {
    clinicaltrials: Object,
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.trials-content {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  background-color: white;
}
</style>
