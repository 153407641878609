<template>
  <div style="height:100%;">
    <div class="app-container">
      <Page
        image="slide_init"
        v-show="currentScreen == 1"
        v-recognizer:swipe.right="prev"
        v-recognizer:swipe.left="next"
      ></Page>
      <Page
        :image="'slide_0' + randomCover + '_l'"
        v-show="currentScreen == 2"
        v-recognizer:swipe.right="prev"
        v-recognizer:swipe.left="next"
      ></Page>
      <Page
        :image="'slide_0' + randomCover + '_r'"
        v-show="currentScreen == 3"
        v-recognizer:swipe.right="prev"
        v-recognizer:swipe.left="next"
      ></Page>
      <Trials
        :clinicaltrials="getTrials()"
        v-show="currentScreen == 4"
        v-recognizer:swipe.right="prev"
        v-recognizer:swipe.left="next"
      ></Trials>
      <Page
        image="slide_end_l"
        v-show="currentScreen == 5"
        v-recognizer:swipe.right="prev"
        v-recognizer:swipe.left="next"
      ></Page>
      <Page
        image="slide_end_r"
        v-show="currentScreen == 6"
        v-recognizer:swipe.right="prev"
        v-recognizer:swipe.left="next"
      ></Page>
    </div>
    <Footer
      @Next_Click="next"
      @Home_Click="home"
      :showCopy="currentScreen == 6"
    ></Footer>
  </div>
</template>
<script>
import $ from "jquery";
import Footer from "@/components/FooterMobile.vue";
import Trials from "@/components/TrialsMobile.vue";
import Page from "@/components/PageMobile.vue";

export default {
  name: "MobileApp",
  components: {
    Trials,
    Page,
    Footer,
  },
  data() {
    return {
      isLoading: false,
      currentScreen: 1,
    };
  },
  computed: {
    randomCover() {
      return Math.floor(Math.random() * 2) + 1;
    },
  },
  props: {
    clinicaltrials: Array,
  },
  watch: {},
  mounted() {},
  methods: {
    home() {
      this.currentScreen = 1;
    },

    next() {
      this.currentScreen = this.currentScreen + 1;
      if (this.currentScreen > 6) this.currentScreen = 6;
    },

    prev() {
      this.currentScreen = this.currentScreen - 1;
      if (this.currentScreen == 0) this.currentScreen = 1;
    },

    getTrials() {
      var results = [];
      if (this.clinicaltrials.length > 0) {
        var conditionstr = this.clinicaltrials[0].condition[0];
        var maxlimit = this.clinicaltrials.length;
        var i;
        for (i = 0; i < maxlimit; i++) {
          if (conditionstr == this.clinicaltrials[i].condition[0])
            results.push({ trial: this.clinicaltrials[i], hasHeader: false });
          else {
            results.push({ trial: this.clinicaltrials[i], hasHeader: true });
            conditionstr = this.clinicaltrials[i].condition[0];
          }
        }
        results[0].hasHeader = true;
      }
      return { trials: results };
    },

    getTrialPaginated(page) {
      var results = [];
      page = page - 1;
      if (this.clinicaltrials.length > 0) {
        var index = page * 9;
        var conditionstr = this.clinicaltrials[index].condition[0];
        var maxlimit = Math.min(index + 9, this.clinicaltrials.length);
        var i;
        for (i = index; i < maxlimit; i++) {
          if (conditionstr == this.clinicaltrials[i].condition[0])
            results.push({ trial: this.clinicaltrials[i], hasHeader: false });
          else {
            results.push({ trial: this.clinicaltrials[i], hasHeader: true });
            conditionstr = this.clinicaltrials[i].condition[0];
          }
        }
        results[0].hasHeader = true;
      }
      return { trials: results };
    },
  },
};
</script>

<style scoped>
body {
  margin: 0px impo !important;
}
.app-container {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-y: auto;
  height: calc(100% - 109px);
}
</style>
