<template>
  <center>
    <div class="book-container">
      <div id="brochure">
        <!-- PAGINA INICIAL -->
        <Page image="slide_init" position="right"></Page>
        <!-- PAGINAS ALEATORIAS DE LOS 3 -->
        <template v-if="randomCover == 1">
          <Page image="slide_02_l" position="left"></Page>
          <Page image="slide_02_r" position="right"></Page>
        </template>
        <template v-if="randomCover == 2">
          <Page image="slide_03_l" position="left"></Page>
          <Page image="slide_03_r" position="right"></Page>
        </template>
        <!-- PAGINAS DE ESTUDIOS -->
        <TrialsPage
          v-for="pageNumber in trialPages"
          v-bind:key="pageNumber"
          v-bind:componentIndex="pageNumber"
          v-bind="getTrialPaginated(pageNumber)"
        ></TrialsPage>
        <!-- PAGINAS DE FINALES -->
        <Page image="slide_05_l" position="left"></Page>
        <Page image="slide_05_r" position="right"></Page>
        <EndPage></EndPage>
      </div>
    </div>
  </center>
</template>

<script>
import $ from "jquery";
import "@/lib/turn.min.js";

import Page from "@/components/Page.vue";
import EndPage from "@/components/EndPage.vue";
import TrialsPage from "@/components/TrialsPage.vue";

export default {
  name: "DesktopApp",
  components: {
    Page,
    TrialsPage,
    EndPage,
  },
  data() {
    return {
      currentPage: 1,
      triggerClicking: false,
      options: {
        width: 1220,
        height: 792,
        autoCenter: true,
        when: {
          turning: (event, page, pageObj) => {
            this.currentPage = page;
          },
          first: () => {
            this.$emit("start");
          },
          last: () => {
            this.$emit("end");
          },
        },
      },
      actualIndex: 0,
    };
  },
  computed: {
    trialPages() {
      return Math.ceil(this.clinicaltrials.length / 9);
    },
    randomCover() {
      return Math.floor(Math.random() * 2) + 1;
    },
  },
  props: {
    clinicaltrials: Array,
    flipOptions: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    currentPage(val) {
      this.$emit("changePage", val);
      $("#brochure .page-wrapper").each(function(index, val) {
        $(val.children[1]).css("display", "none");
      });
    },
  },
  mounted() {
    var that = this;
    // CONFIGURACION INICIAL DEL LIBRO
    if (that.flipOptions && Object.keys(that.flipOptions).length) {
      Object.keys(that.flipOptions).forEach((n) => {
        that.options[n] = that.flipOptions[n];
      });
    }

    setTimeout(function() {
      $("#brochure").turn(that.options);
    }, 1500);

    // MAPEO DE LAS TECLAS PARA PASAR LAS PAGINAS
    $(window).bind("keydown", function(e) {
      if (e.keyCode == 37 || e.keyCode == 34) $("#brochure").turn("previous");
      else if (e.keyCode == 39 || e.keyCode == 32 || e.keyCode == 33)
        $("#brochure").turn("next");
    });
  },
  methods: {
    getTrialPaginated(page) {
      var results = [];
      page = page - 1;
      if (this.clinicaltrials.length > 0) {
        var index = page * 9;
        var conditionstr = this.clinicaltrials[index].condition[0];
        var maxlimit = Math.min(index + 9, this.clinicaltrials.length);
        var i;
        for (i = index; i < maxlimit; i++) {
          if (conditionstr == this.clinicaltrials[i].condition[0])
            results.push({ trial: this.clinicaltrials[i], hasHeader: false });
          else {
            results.push({ trial: this.clinicaltrials[i], hasHeader: true });
            conditionstr = this.clinicaltrials[i].condition[0];
          }
        }
        results[0].hasHeader = true;
      }
      return { trials: results };
    },
  },
};
</script>

<style scope>
.book-container {
  margin-top: 50px;
  overflow: hidden;
}

div.flip-control {
  width: 400px;
  text-align: center;
}

div.flip-control a {
  margin-left: 10px;
}

#brochure div {
  position: relative;
}
</style>
