import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueRecognizer from 'vue-recognizer';
import Modal from "@burhanahmeed/vue-modal-2";
import store from './store'


Vue.config.productionTip = false;
window.$ = window.jQuery = require('jquery');
window.axios = axios;
Vue.prototype.$http = axios;
Vue.use(VueRecognizer);
Vue.use(Modal);
Vue.config.productionTip = false;


new Vue({
    store,
    render: function(h) { return h(App) }
}).$mount('#app')