<template>
  <div>
    <img :src="pageImage" />
    <img
      class="destello"
      v-bind:class="{ previous: isLeftPage, next: isRightPage }"
      src="@/assets/arrow.png"
    />
  </div>
</template>

<script>
import Slide_Init from "@/assets/slide_init.jpg";
import Slide_02_l from "@/assets/slide_02_l.jpg";
import Slide_02_r from "@/assets/slide_02_r.jpg";
import Slide_03_r from "@/assets/slide_03_r.jpg";
import Slide_03_l from "@/assets/slide_03_l.jpg";
import Slide_04_l from "@/assets/slide_04_l.jpg";
import Slide_04_r from "@/assets/slide_04_r.jpg";
import Slide_05_l from "@/assets/slide_05_l.jpg";
import Slide_05_r from "@/assets/slide_05_r.jpg";

export default {
  name: "Page",
  data() {
    return {};
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: true,
    },
  },
  computed: {
    isLeftPage() {
      return this.position == "left";
    },
    isRightPage() {
      return this.position == "right";
    },
    pageImage() {
      switch (this.image) {
        case "slide_init":
          return Slide_Init;
          break;
        case "slide_02_l":
          return Slide_02_l;
          break;
        case "slide_02_r":
          return Slide_02_r;
          break;
        case "slide_03_r":
          return Slide_03_r;
          break;
        case "slide_03_l":
          return Slide_03_l;
          break;
        case "slide_04_l":
          return Slide_04_l;
          break;
        case "slide_04_r":
          return Slide_04_r;
          break;
        case "slide_05_l":
          return Slide_05_l;
          break;
        case "slide_05_r":
          return Slide_05_r;
          break;
      }
    },
  },
  methods: {},
};
</script>

<style scoped>
.destello {
  position: absolute;
}
.next {
  width: 35px;
  transform: rotate(-45deg);
  right: 0;
  top: 0;
  animation: destello-efecto-01 3s infinite;
  -webkit-animation: destello-efecto-01 3s infinite;
}
.previous {
  width: 35px;
  transform: rotate(-135deg);
  left: 0;
  top: 0;
  animation: destello-efecto-02 3s infinite;
  -webkit-animation: destello-efecto-02 3s infinite;
}
/* #brochure div .previous{right: 550px; top: -50px; animation: destello-efecto-01 3s infinite; -webkit-animation: destello-efecto-01 3s infinite;} */
@keyframes destello-efecto-01 {
  0% {
    right: 10px;
    top: 10px;
  }
  50% {
    right: 0px;
    top: 0px;
  }
  100% {
    right: 10px;
    top: 10px;
  }
}
@-webkit-keyframes destello-efecto-01 {
  0% {
    right: 10px;
    top: 10px;
  }
  50% {
    right: 0px;
    top: 0px;
  }
  100% {
    right: 10px;
    top: 10px;
  }
}
@keyframes destello-efecto-02 {
  0% {
    left: 10px;
    top: 10px;
  }
  50% {
    left: 0px;
    top: 0px;
  }
  100% {
    left: 10px;
    top: 10px;
  }
}
@-webkit-keyframes destello-efecto-02 {
  0% {
    left: 10px;
    top: 10px;
  }
  50% {
    left: 0px;
    top: 0px;
  }
  100% {
    left: 10px;
    top: 10px;
  }
}
</style>
