<template>
  <div
    class="footer"
    v-bind:class="{ 'regular-footer': !showCopy, 'copyright-footer': showCopy }"
  >
    <div class="regular" v-if="!showCopy">
      <img class="logo" src="@/assets/mobile/logo.png" />
      <img
        @click="onbtnNextClick"
        class="button"
        src="@/assets/mobile/btnnext.png"
      />
    </div>
    <div class="copyright" v-else>
      <p class="company-name">Merck Oncology</p>
      <p class="legal-text">
        Copyright © 2021 Merck Sharp & Dohme Corp., a subsidiary of Merck & Co.,
        Inc., all rights reserved.<br />MI-ONC-0178-US
        <span class="jobcode-date">{{ currentDate }}</span>
      </p>
      <p class="legal-text">
        The content of this brochure is based on clinicaltrials.gov accessed on
        <span class="revision-date">{{ currentDate }}</span>
      </p>
      <img
        @click="onbtnHomeClick"
        class="button"
        src="@/assets/mobile/btnhome.png"
      />
    </div>
  </div>
</template>

<script>
import moment, { now } from "moment";

export default {
  name: "Footer",
  components: {},
  data() {
    return {};
  },
  computed: {
    currentDate() {
      return moment(Date.now()).format("M/YY");
    },
  },
  props: {
    showCopy: Boolean,
  },
  watch: {},
  mounted() {},
  methods: {
    onbtnNextClick: function(event) {
      this.$emit("Next_Click");
    },
    onbtnHomeClick: function(event) {
      this.$emit("Home_Click");
    },
  },
};
</script>

<style scoped>
.footer {
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: 0;
  left: 0;
  border-top-color: #feca33;
  border-top-width: 10px;
  border-top-style: solid;
}

.regular-footer {
  background: #008d8e;
  background: -moz-linear-gradient(
    90deg,
    #008d8e 0%,
    rgba(255, 255, 255, 1) 50%
  );
  background: -webkit-linear-gradient(
    90deg,
    #008d8e 0%,
    rgba(255, 255, 255, 1) 50%
  );
  background: linear-gradient(90deg, #008d8e 0%, rgba(255, 255, 255, 1) 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008d8e",endColorstr="#ffffff",GradientType=1);
}

.copyright-footer {
  background: #008d8e05;
  background: -moz-linear-gradient(
    90deg,
    #008d8e -25%,
    rgba(255, 255, 255, 1) 0%
  );
  background: -webkit-linear-gradient(
    90deg,
    #008d8e -25%,
    rgba(255, 255, 255, 1) 40%
  );
  background: linear-gradient(90deg, #008d8e -25%, rgba(255, 255, 255, 1) 40%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008d8e",endColorstr="#ffffff",GradientType=1);
}

.footer .regular .logo {
  left: 30px;
  position: absolute;
  top: 33%;
}

.footer .regular .button {
  right: 30px;
  position: absolute;
  top: 33%;
  cursor: pointer;
}

.footer .copyright {
  text-align: left;
  padding: 5px;
}

.footer .copyright p {
  margin: 5px;
}

.footer .copyright .button {
  position: absolute;
  right: 5px;
  bottom: 5px;
  cursor: pointer;
}

.company-name {
  color: #00877d;
  font-weight: bold;
  font-family: "Oswald", sans-serif;
  font-size: small;
}

.legal-text {
  font-family: "Oswald", sans-serif;
  font-size: x-small;
}
</style>
