<template>
  <div v-bind:class="pagePosition">
    <img
      class="destello"
      v-bind:class="{ previous: isLeftPage, next: isRightPage }"
      src="@/assets/arrow.png"
    />
    <div style="margin-top: 40px;">
      <ClinicalTrial
        v-for="(item, index) in trials"
        :key="index"
        v-bind="item"
      ></ClinicalTrial>
    </div>
  </div>
</template>

<script>
import ClinicalTrial from "@/components/ClinicalTrial.vue";

export default {
  name: "TrialsPage",
  components: {
    ClinicalTrial,
  },
  props: {
    trials: {
      type: Array,
      required: true,
    },
    componentIndex: Number,
  },
  computed: {
    isLeftPage() {
      return this.componentIndex % 2 != 0;
    },
    isRightPage() {
      return this.componentIndex % 2 == 0;
    },
    pagePosition() {
      if (this.componentIndex % 2 == 0) return "page_r";
      else return "page_l";
    },
  },
};
</script>

<style scoped>
.page_l {
  background-image: url(../assets/slide_04_l.jpg) !important;
  background-repeat: no-repeat !important;
  background-position-y: center !important;
  background-position-x: center !important;
  background-size: cover !important;
  background-color: rgba(46, 120, 142, 1) !important;
}

.page_r {
  background-image: url(../assets/slide_04_r.jpg) !important;
  background-repeat: no-repeat !important;
  background-position-y: center !important;
  background-position-x: center !important;
  background-size: cover !important;
  background-color: rgba(46, 120, 142, 1) !important;
}

.destello {
  position: absolute;
}
.next {
  width: 35px;
  transform: rotate(-45deg);
  right: 0;
  top: 0;
  animation: destello-efecto-01 3s infinite;
  -webkit-animation: destello-efecto-01 3s infinite;
}
.previous {
  width: 35px;
  transform: rotate(-135deg);
  left: 0;
  top: 0;
  animation: destello-efecto-02 3s infinite;
  -webkit-animation: destello-efecto-02 3s infinite;
}

@keyframes destello-efecto-01 {
  0% {
    right: 10px;
    top: 10px;
  }
  50% {
    right: 0px;
    top: 0px;
  }
  100% {
    right: 10px;
    top: 10px;
  }
}
@-webkit-keyframes destello-efecto-01 {
  0% {
    right: 10px;
    top: 10px;
  }
  50% {
    right: 0px;
    top: 0px;
  }
  100% {
    right: 10px;
    top: 10px;
  }
}
@keyframes destello-efecto-02 {
  0% {
    left: 10px;
    top: 10px;
  }
  50% {
    left: 0px;
    top: 0px;
  }
  100% {
    left: 10px;
    top: 10px;
  }
}
@-webkit-keyframes destello-efecto-02 {
  0% {
    left: 10px;
    top: 10px;
  }
  50% {
    left: 0px;
    top: 0px;
  }
  100% {
    left: 10px;
    top: 10px;
  }
}
</style>
