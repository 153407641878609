<template>
  <div style="height:100%;">
    <img class="page" :src="pageImage" />
  </div>
</template>
<script>
import Slide_Init from "@/assets/mobile/slide_init_mobile.jpg";
import Slide_01_l from "@/assets/mobile/slide_01_l_mobile.jpg";
import Slide_01_r from "@/assets/mobile/slide_01_r_mobile.jpg";
import Slide_02_l from "@/assets/mobile/slide_02_l_mobile.jpg";
import Slide_02_r from "@/assets/mobile/slide_02_r_mobile.jpg";
import Slide_End_r from "@/assets/mobile/slide_end_r.jpg";
import Slide_End_l from "@/assets/mobile/slide_end_l.jpg";

export default {
  name: "Page",
  components: {},
  data() {
    return {};
  },
  computed: {
    pageImage() {
      switch (this.image) {
        case "slide_init":
          return Slide_Init;
          break;
        case "slide_01_l":
          return Slide_01_l;
          break;
        case "slide_01_r":
          return Slide_01_r;
          break;
        case "slide_02_r":
          return Slide_02_r;
          break;
        case "slide_02_l":
          return Slide_02_l;
          break;
        case "slide_end_l":
          return Slide_End_l;
          break;
        case "slide_end_r":
          return Slide_End_r;
          break;
      }
    },
  },
  props: {
    image: {
      type: String,
      required: true,
    },
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.page {
  width: 100%;
  height: 100%;
}
</style>
