<template>
  <div>
    <!-- HEADER -->
    <img v-if="hasHeader" :src="config[condition].header" />
    <!-- CELL -->
    <div
      class="content"
      v-on:click="onTrialClick"
      style="cursor: pointer !important;"
    >
      <img :src="config[condition].background" />
      <div class="title">
        <p class="titleText">{{ trial.title }}</p>
      </div>
      <p class="phase">{{ trial.phase }}</p>
      <p class="nct">{{ trial.nct }}</p>
      <div :id="trial.nct + '_' + trial.condition" class="qr"></div>
    </div>
  </div>
</template>

<script>
var qr = require("qr.js");

import Header_NEW from "@/assets/header_new.png";
import Header_SKIN from "@/assets/header_skin.png";
import Header_HEAD from "@/assets/header_head.png";
import Header_GASTRO from "@/assets/header_gastro.png";
import Header_LUNG from "@/assets/header_lung.png";
import Header_BREAST from "@/assets/header_breast.png";
import Header_GYNECOLOGICAL from "@/assets/header_gynecological.png";
import Header_GENITO from "@/assets/header_genito.png";
import Header_SOLID from "@/assets/header_solid.png";
import Header_HEMA from "@/assets/header_hema.png";
import Header_CNS from "@/assets/header_cns.png";

import Background_NEW from "@/assets/background_new.png";
import Background_SKIN from "@/assets/background_skin.png";
import Background_HEAD from "@/assets/background_head.png";
import Background_GASTRO from "@/assets/background_gastro.png";
import Background_LUNG from "@/assets/background_lung.png";
import Background_BREAST from "@/assets/background_breast.png";
import Background_GYNECOLOGICAL from "@/assets/background_gynecological.png";
import Background_GENITO from "@/assets/background_genito.png";
import Background_SOLID from "@/assets/background_solid.png";
import Background_HEMA from "@/assets/background_hema.png";
import Background_CNS from "@/assets/background_cns.png";

export default {
  name: "Clinicaltrial",
  data() {
    return {
      config: {
        new: {
          qrcolor: "#FDC932",
          header: Header_NEW,
          background: Background_NEW,
        },
        skin: {
          qrcolor: "#0E0F0E",
          header: Header_SKIN,
          background: Background_SKIN,
        },
        head: {
          qrcolor: "#E01F1D",
          header: Header_HEAD,
          background: Background_HEAD,
        },
        gastro: {
          qrcolor: "#352D86",
          header: Header_GASTRO,
          background: Background_GASTRO,
        },
        lung: {
          qrcolor: "#0098D4",
          header: Header_LUNG,
          background: Background_LUNG,
        },
        breast: {
          qrcolor: "#EA5892",
          header: Header_BREAST,
          background: Background_BREAST,
        },
        gynecological: {
          qrcolor: "#00A69F",
          header: Header_GYNECOLOGICAL,
          background: Background_GYNECOLOGICAL,
        },
        genito: {
          qrcolor: "#E8550D",
          header: Header_GENITO,
          background: Background_GENITO,
        },
        solid: {
          qrcolor: "#168C40",
          header: Header_SOLID,
          background: Background_SOLID,
        },
        hema: {
          qrcolor: "#861262",
          header: Header_HEMA,
          background: Background_HEMA,
        },
        cns: {
          qrcolor: "#9C9B9B",
          header: Header_CNS,
          background: Background_CNS,
        },
      },
    };
  },
  props: {
    trial: {
      type: Object,
      required: true,
    },
    hasHeader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    condition() {
      return this.trial.condition[0];
    },
  },
  mounted() {
    var text = "https://clinicaltrials.gov/ct2/show/" + this.trial.nct;
    var qrcode = qr(text);

    var width = 200;
    var height = 200;

    var canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    var ctx = canvas.getContext("2d");

    var cells = qrcode.modules;

    var tileW = width / cells.length;
    var tileH = height / cells.length;

    for (var r = 0; r < cells.length; ++r) {
      var row = cells[r];
      for (var c = 0; c < row.length; ++c) {
        ctx.fillStyle = row[c] ? this.config[this.condition].qrcolor : "#fff";
        var w = Math.ceil((c + 1) * tileW) - Math.floor(c * tileW);
        var h = Math.ceil((r + 1) * tileH) - Math.floor(r * tileH);
        ctx.fillRect(Math.round(c * tileW), Math.round(r * tileH), w, h);
      }
    }
    canvas.style.width = "50px";
    $("#" + this.trial.nct + "_" + this.trial.condition).append(canvas);
  },
  methods: {
    onTrialClick: function(event) {
      window.open(
        "https://clinicaltrials.gov/ct2/show/" + this.trial.nct,
        "_blank"
      );
    },
  },
};
</script>

<style scoped>
.content {
  width: 480px;
  height: 64px;
}

.phase {
  position: absolute;
  top: 12px;
  left: 320px;
  width: 50px;
  height: 15px;
  text-align: left;
  font-size: x-small;
  font-weight: normal;
}
.nct {
  position: absolute;
  top: 12px;
  left: 350px;
  width: 70px;
  height: 15px;
  text-align: left;
  font-size: x-small;
  font-weight: normal;
}
.titleText {
  font-size: x-small;
  font-weight: normal;
  text-align: left;
  margin: auto;
}
.title {
  top: 0px;
  margin: 0px;
  position: absolute !important;
  left: 15px;
  width: 290px;
  height: 64px;
  vertical-align: middle;
  display: flex;
  margin: auto;
}
.qr {
  position: absolute !important;
  top: 5px;
  left: 418px;
  width: 50px;
  height: 50px;
}
</style>
